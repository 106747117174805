import React from 'react';

const Index = (props) => {
  const { data } = props;

  return (
    <a href={`/market-data/quotes/{data.name}?mod=chiclets`} class="css-2ja3dl-ChicletStyle e1vl9ktx2">{data.name}{" "}
      <span class="css-1hk2j0i-ChicletChange e1vl9ktx3">0.59%</span>
      <span class="css-jozwg9-Arrow e1vl9ktx1">
        <span class="css-1q7p60h-ArrowHiddenLabel e1vl9ktx0">increase; green up pointing triangle</span>
        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="16" height="16" viewBox="0 0 16 16">
          <defs>
            <path id="arrow-down-filled-small_svg__a" d="M8.588 3.985l4.927 7.14-.506.86H3.02l-.534-.86 4.959-7.14z"></path>
          </defs>
          <use fill="currentColor" fill-rule="evenodd" transform="rotate(-180 8.008 7.992)" xlinkHref="#arrow-down-filled-small_svg__a"></use>
        </svg>
      </span>
    </a>
  )
};

export default Index;