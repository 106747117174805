import React, { useContext } from 'react';
import Asset from "./Asset";
import { AssetsContext } from '../Contexts';

const Text = (props) => {
  const { data } = props;

  const assets = useContext(AssetsContext);

  const regex = /{([^}]+)}/g;
  let lastIndex = 0;
  const elements = [];

  data.replace(regex, (match, group, index) => {
    const textBefore = data.substring(lastIndex, index);
    if (textBefore) {
      elements.push(textBefore);
    }

    const key = group.replace(/[{}]/g, '');
    elements.push(<Asset key={key} data={assets[key]} />);

    lastIndex = index + match.length;
    return '';
  });

  const textAfter = data.substring(lastIndex);
  if (textAfter) {
    elements.push(textAfter);
  }

  const result = regex.exec(data)
  const text = (<>{elements}</>)
  if (result && result[0] === data) {
    return text;
  }

  return (
    <>{text}</>
  );
};

export default Text;