import React from 'react';

const Video = (props) => {
  const { data } = props;

  return (
    <div data-type={data.type} data-inset_type="" data-sub_type="" class="media-layout css-gly1v4-Layout-baseCss ertdlv30" data-layout={data.layout}>
      <figure class="css-wgssxl-Figure e1bulelc4">
        <div class="css-m2oeyh-Strap e1bulelc2"></div>
        <div id="videoF9EEF71B-BC7E-422B-A7C6-F796AA2B12E4" format="standard" data-up-next="false" class="css-11r2c2u video-player" aria-label="How Jamie Dimon Turned Chase Into the Most Powerful Bank in the U.S." tabindex="-1">
          <div id="wrapper-videoF9EEF71B-BC7E-422B-A7C6-F796AA2B12E4" data-server-state="" class="video-wrapper player-8U">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="128"
              height="128"
              enableBackground="new 0 0 32 32"
              viewBox="0 0 32 32"
            >
              <path
                fill="#4E4E50"
                d="M16 0C7.164 0 0 7.164 0 16s7.164 16 16 16 16-7.164 16-16S24.836 0 16 0zm-6 24V8l16.008 8L10 24z"
              ></path>
            </svg>
          </div>
        </div>
        <figcaption class="css-1oq9ce8-Figcaption e1bulelc3">{data.caption}</figcaption>
      </figure>
    </div>
  )
};

export default Video;