import Article from "./components/Article";
import "./App.css";

function App() {
  return (
    <div className="App">
      <Article id={`api.json?rnd=${Math.random()}`} />
    </div>
  );
}

export default App;
