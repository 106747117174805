import React, { useEffect, useState } from 'react';
import { AssetsContext } from '../Contexts';
import Text from "./Text";

const Article = (props) => {
  const { id } = props;
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(id);
        const jsonData = await response.json();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching JSON:', error);
      }
    };

    fetchData();
  }, [id]);

  return (
    <div>
      {data ? (
        <div className="layout-grid css-i334ia">
          <main class="css-1yfy42w">
          <AssetsContext.Provider value={data.assets.reduce((acc, cur) => {
                      acc[cur.assetId] = cur;
                      return acc;
          }, {})}>
            {data.cover && <div class="css-1043x6s e1of74uw15">
              <div data-block="big-top">
                <div class="css-u9kaxk-Root-Root">
                  <div class="css-1ieir01-HeadlineContainer e1fgnxat4">
                    <div class="crawler css-bb771t-HeadlineWrapper e1fgnxat3">
                      <h1 class="evxsf2b0 css-1ruciut-StyledHeadline-BigTopHeadline e1ipbpvp0">{data.cover.title && data.cover.title.length && <Text data={data.cover.title} />}</h1>
                      <h2 class="css-nqdt4q-Dek-Dek-SplitTopDek e1fgnxat5">{data.cover.subtitle && data.cover.subtitle.length && <Text data={data.cover.subtitle} />}</h2>
                    </div>
                  </div>
                  {data.cover.image && data.cover.image.length && 
                    <div data-block="doNotPrint" class="e1fgnxat1 css-u21k0r-Wrapper-SplitTopImage eyts7et0" role="img" aria-describedby="big-top-caption" alt=""><Text data={data.cover.image} /></div>
                  }
                </div>
              </div>
            </div>}
            <div className="article-container css-1fasr7">
              <div className="article-header css-exmfr">
                <div className="css-j6808u">
                  <div className="crawler css-bsrkcm-Box">
                    <h1 className="css-1lvqw7f-StyledHeadline">
                      {data.title && data.title.length && <Text data={data.title} />}
                    </h1>
                  </div>
                  <h2 className="css-jiugt2-Dek-Dek">
                    {data.subtitle && data.subtitle.length && <Text data={data.subtitle} />}
                  </h2>
                  
                </div>
              </div>
              <article className="css-15rv4ep">
                <div className="crawler css-symnra">
                  <section className="css-y2scx8-Container">
                    {data.body.map((item, index) => (
                      <p
                      data-type="paragraph"
                      className="css-k3zb6l-Paragraph">
                        <Text key={index} data={item} />
                      </p>
                    ))}
                    <p
                      data-type="paragraph"
                      className="css-k3zb6l-Paragraph css-authors"
                    >
                    {data.authors.map((item, index) => (
                      <>
                        <span>{item.name} at{" "}</span>
                        <a
                          key={index}
                          data-type="link"
                          href="mailto:{item.email}"
                          rel=""
                          className="css-1h1us5y-StyledLink"
                        >
                          {item.email}
                        </a>{" "}
                      </>
                    ))}
                    </p>
                  </section>
                </div>
              </article>
            </div>
            </AssetsContext.Provider>
          </main>
          </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default Article;