import React from 'react';
import Index from './Index';
import Image from './Image';
import Video from './Video';

const Asset = (props) => {
  const { data } = props;

  switch (data.type) {
    case 'index':
      return (<Index data={data} />);
    case 'image':
    case 'inset':
    case 'cover':
      return (<Image data={data} />);
    case 'video':
      return (<Video data={data} />);
    default:
      return (<>{data.type}</>);
  }
};

export default Asset;