import React from 'react';

const Picture = (props) => {
  const { data } = props;

  return (
    <>
      <figure className="css-x5rdl7-Figure">
        <picture className="css-vl2swp">
          <img
            alt=""
            sizes="(max-width: 639px) 100vw, (max-width: 979px) 620px, (max-width: 1299px) 540px, 700px"
            srcset={`https://images.wsj.net/${data.assetId}?width=540&amp;size=1.4780600461893765 540w, https://images.wsj.net/${data.assetId}?width=620&amp;size=1.4780600461893765 620w, https://images.wsj.net/${data.assetId}?width=639&amp;size=1.4780600461893765 639w, https://images.wsj.net/${data.assetId}?width=700&amp;size=1.4780600461893765 700w, https://images.wsj.net/${data.assetId}?width=700&amp;size=1.4780600461893765&amp;pixel_ratio=1.5 1050w, https://images.wsj.net/${data.assetId}?width=700&amp;size=1.4780600461893765&amp;pixel_ratio=2 1400w, https://images.wsj.net/${data.assetId}?width=700&amp;size=1.4780600461893765&amp;pixel_ratio=3 2100w`}
            width="700"
            height="474"
            loading="lazy"
            src={`https://images.wsj.net/${data.assetId}?width=700&amp;height=474`}
            className="css-gt0p44"
          />
        </picture>
      </figure>
      <figcaption className="css-13dclxy-FigcaptionItem">
        {data.caption && data.caption.length &&
          <span className="css-jb39dk-CaptionSpan">{data.caption}</span>
        }
        {data.copyrights && data.copyrights.length &&
          <span className="css-7jz429-Credit">
            <span>Photo: </span>{data.copyrights}{" "}
          </span>
        }
      </figcaption>
    </>
  )
}

const Background = (props) => {
  const { data } = props;

  let alignClass

  switch (data.layout){
    case "wrap": 
      alignClass = 'css-ggu923-Layout-baseCss';
    break;
    case "inline": 
      alignClass = 'css-gly1v4-Layout-baseCss';
    break;
    default:
      alignClass = '';
  }

  return (
    <>
    <div style={{
      position: "relative",
      aspectRatio: data.ratio,
      backgroundSize: "cover",
      backgroundPosition: "50% center",
      backgroundImage: `url(https://images.wsj.net/${data.assetId}?width=600)`
    }} className={`wrap ${alignClass}`}></div>
    <figcaption className="css-182unkf-BigTopCaption-BigTopCaption-centerAlignCaption-rightAlignCaption-rightAlignCaption ej6vcoy0">
        {data.copyrights && data.copyrights.length &&
          <span className="css-7jz429-Credit eq0esvu0">
            {data.copyrights}{" "}
          </span>
        }
    </figcaption>
    </>
  )
}

const Cover = (props) => {
  const { data } = props;

  return (
    <>
      <div data-block="doNotPrint" class=" e1fgnxat1 css-u21k0r-Wrapper-SplitTopImage eyts7et0" role="img" aria-describedby="big-top-caption" alt="">
        <picture className="css-vl2swp">
          <img
            alt=""
            width="700"
            height="474"
            src={`https://images.wsj.net/${data.assetId}?width=700&amp;height=474`}
            className="css-1fhld9r"
          />
        </picture>
      </div>
      <figcaption className="css-182unkf-BigTopCaption-BigTopCaption-centerAlignCaption-rightAlignCaption-rightAlignCaption ej6vcoy0">
        {data.copyrights && data.copyrights.length &&
          <span className="css-7jz429-Credit">
            <span></span>{data.copyrights}{" "}
          </span>
        }
      </figcaption>
    </>
  )
}

const Image = (props) => {
  const { data } = props;

  let alignClass

  switch (data.layout){
    case "wrap": 
      alignClass = 'css-ggu923-Layout-baseCss';
    break;
    case "inline": 
      alignClass = 'css-gly1v4-Layout-baseCss';
    break;
    default:
      alignClass = '';
  }

  return (
    <div
      data-type={data.type}
      data-inset_type=""
      data-sub_type=""
      className={`media-layout ${alignClass}`}
      data-layout={data.layout}
    >
      {data.type === 'image' && <Picture data={data} />}
      {data.type === 'inset' && <Background data={data} />}
      {data.type === 'cover' && <Cover data={data} />}
    </div>
  )
};

export default Image;